// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quests {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 90vh;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.quests::-webkit-scrollbar {
  display: none;
}

.quest-block {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.quest-block.completed {
  border-color: gold;
}

.quest-block.pending {
  border-color: #656565;
}

.quest-status {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.quest-content {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components/quests/Quests.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,gBAAA;EACA,wBAAA;EACD,qBAAA;AACD;AAAC;EACC,aAAA;AAEF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,kCAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,SAAA;AACF","sourcesContent":[".quests {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  max-height: 90vh;\n  overflow: scroll;\n  -ms-overflow-style: none;\n\tscrollbar-width: none;\n\t&::-webkit-scrollbar {\n\t\tdisplay: none;\n\t}\n}\n\n.quest-block {\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  border: 2px solid #ccc;\n  border-radius: 5px;\n  transition: border-color 0.3s ease;\n}\n\n.quest-block.completed {\n  border-color: gold;\n}\n\n.quest-block.pending {\n  border-color: #656565;\n}\n\n.quest-status {\n  width: 40px;\n  height: 40px;\n  margin-right: 10px;\n}\n\n.quest-content {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
