// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.achievements-item {
    border: 1px solid rgb(107 107 107);
    margin: 10px;
    padding: 10px;
    width: 25%;
    max-width: 150px;
    border-radius: 10px;
  }
  
  .achievements-item .item-title {
    padding: 5px;
    text-align: center;
    border-bottom: 1px solid #6b6b6b;
    color: #ffffff;
  }
  
  .achievements-items-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }
  
  .achievements-item button.buy-button {
    margin-top: 10px;
    width: 100%;
    color: #fff;
    border: 1px solid #fff;
  }
  
  .achievements-item button.buy-button:hover {
    background: #77777761;
  }
  
  p.coming-soon {
    text-align: center;
    color: #ffffff85;
  }
  
  @media (max-width: 768px) {
    
    .achievements-item {
      width: 100%;
      margin: 15px 0;
    }
    
    .achievements-items-row {
      justify-content: center;
    }
  
  }
  `, "",{"version":3,"sources":["webpack://./src/components/achievements/Achievements.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,YAAY;IACZ,aAAa;IACb,UAAU;IACV,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,gCAAgC;IAChC,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,qBAAqB;EACvB;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,WAAW;IACX,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;;IAEE;MACE,WAAW;MACX,cAAc;IAChB;;IAEA;MACE,uBAAuB;IACzB;;EAEF","sourcesContent":[".achievements-item {\n    border: 1px solid rgb(107 107 107);\n    margin: 10px;\n    padding: 10px;\n    width: 25%;\n    max-width: 150px;\n    border-radius: 10px;\n  }\n  \n  .achievements-item .item-title {\n    padding: 5px;\n    text-align: center;\n    border-bottom: 1px solid #6b6b6b;\n    color: #ffffff;\n  }\n  \n  .achievements-items-row {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: left;\n  }\n  \n  .achievements-item button.buy-button {\n    margin-top: 10px;\n    width: 100%;\n    color: #fff;\n    border: 1px solid #fff;\n  }\n  \n  .achievements-item button.buy-button:hover {\n    background: #77777761;\n  }\n  \n  p.coming-soon {\n    text-align: center;\n    color: #ffffff85;\n  }\n  \n  @media (max-width: 768px) {\n    \n    .achievements-item {\n      width: 100%;\n      margin: 15px 0;\n    }\n    \n    .achievements-items-row {\n      justify-content: center;\n    }\n  \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
