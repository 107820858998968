// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-item {
  border: 1px solid rgb(107 107 107);
  margin: 10px;
  padding: 10px;
  width: 25%;
  max-width: 150px;
  border-radius: 10px;
}

.store-item .item-title {
  padding: 5px;
  text-align: center;
  border-bottom: 1px solid #6b6b6b;
  color: #ffffff;
}

.store-items-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.store-item button.buy-button {
  margin-top: 10px;
  width: 100%;
  color: #fff;
  border: 1px solid #fff;
}

.store-item button.buy-button:hover {
  background: #77777761;
}

p.coming-soon {
  text-align: center;
  color: #ffffff85;
}

@media (max-width: 768px) {
  
  .store-item {
    width: 100%;
    margin: 15px 0;
  }
  
  .store-items-row {
    justify-content: center;
  }

}
`, "",{"version":3,"sources":["webpack://./src/components/marketplace/Marketplace.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,YAAY;EACZ,aAAa;EACb,UAAU;EACV,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,gCAAgC;EAChC,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;;EAEE;IACE,WAAW;IACX,cAAc;EAChB;;EAEA;IACE,uBAAuB;EACzB;;AAEF","sourcesContent":[".store-item {\n  border: 1px solid rgb(107 107 107);\n  margin: 10px;\n  padding: 10px;\n  width: 25%;\n  max-width: 150px;\n  border-radius: 10px;\n}\n\n.store-item .item-title {\n  padding: 5px;\n  text-align: center;\n  border-bottom: 1px solid #6b6b6b;\n  color: #ffffff;\n}\n\n.store-items-row {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: left;\n}\n\n.store-item button.buy-button {\n  margin-top: 10px;\n  width: 100%;\n  color: #fff;\n  border: 1px solid #fff;\n}\n\n.store-item button.buy-button:hover {\n  background: #77777761;\n}\n\np.coming-soon {\n  text-align: center;\n  color: #ffffff85;\n}\n\n@media (max-width: 768px) {\n  \n  .store-item {\n    width: 100%;\n    margin: 15px 0;\n  }\n  \n  .store-items-row {\n    justify-content: center;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
